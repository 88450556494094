import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import { Link } from 'gatsby';
import { EuiSpacer } from '@elastic/eui';
import NavButtons from 'components/Motion_Detection/INSTAR_Cloud/NavButtons';
import ForumBox from 'components/Motion_Detection/INSTAR_Cloud/ForumBox';
import BreadCrumbs from 'components/Layout/BreadCrumbs';
import SEOHelmet from 'components/Layout/SEOHelmet';
export const _frontmatter = {
  "title": "INSTAR Cloud IFTTT Support",
  "path": "/Motion_Detection/INSTAR_Cloud/IFTTT/",
  "dateChanged": "2020-10-19",
  "author": "Mike Polinowski",
  "excerpt": "On the IFTTT Platform, we provided a service named INSTAR. The INSTAR Applets provides a way for you to control some settings of your INSTAR Camera or INSTAR Cloud with IFTTT. You can connect INSTAR to Location and Date & Time triggers and receive Notifications and more. For example, you can connected the INSTAR Applets with the IFTTT Location service and to turn off the alarm when you arrive at home. You can also use the INSTAR Applets to build your own automation connecting them to other third-party Applets.",
  "image": "./MD_SearchThumb_Cloud_IFTTT.png",
  "social": "/images/Search/MD_SearchThumb_Cloud_IFTTT.webp",
  "toc": "/images/Search/TOC_Icons/Wiki_Tiles_MD-INSTAR-Cloud-IFTTT_white.webp",
  "chapter": "Motion Detection"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <SEOHelmet title='INSTAR Cloud IFTTT Support' dateChanged='2020-10-19' author='Mike Polinowski' tag='INSTAR IP Camera' description='On the IFTTT Platform, we provided a service named INSTAR. The INSTAR Applets provides a way for you to control some settings of your INSTAR Camera or INSTAR Cloud with IFTTT. You can connect INSTAR to Location and Date & Time triggers and receive Notifications and more. For example, you can connected the INSTAR Applets with the IFTTT Location service and to turn off the alarm when you arrive at home. You can also use the INSTAR Applets to build your own automation connecting them to other third-party Applets.' image='/images/Search/MD_SearchThumb_Cloud_IFTTT.png' twitter='/images/Search/MD_SearchThumb_Cloud_IFTTT.webp' location={props.location.pathname} mdxType="SEOHelmet" />
    <BreadCrumbs locationBC={props.location} locationEN={props.location.pathname} locationDE='/de/Bewegungserkennung/INSTAR_Cloud/IFTTT/' locationFR='/fr/Motion_Detection/INSTAR_Cloud/IFTTT/' crumbLabel="IFTTT" mdxType="BreadCrumbs" />
    <NavButtons mdxType="NavButtons" />
    <h2 {...{
      "id": "instar-cloud",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#instar-cloud",
        "aria-label": "instar cloud permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`INSTAR Cloud`}</h2>
    <h3 {...{
      "id": "ifttt-support",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#ifttt-support",
        "aria-label": "ifttt support permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`IFTTT Support`}</h3>
    <p>{`IFTTT derives its name from the programming conditional statement “if this, then that.” What the company provides is a software platform that connects apps, devices and services from different developers in order to trigger one or more automation involving those apps, devices and services.`}</p>
    <p>{`On the IFTTT Platform, we provided a service named INSTAR. The INSTAR Applets provides a way for you to control some settings of your INSTAR Camera or INSTAR Cloud with IFTTT. You can connect INSTAR to Location and Date & Time triggers and receive Notifications and more. For example, you can connected the INSTAR Applets with the IFTTT Location service and to turn off the alarm when you arrive at home. You can also use the INSTAR Applets to build your own automation connecting them to other third-party Applets.`}</p>
    <EuiSpacer mdxType="EuiSpacer" />
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/en/Motion_Detection/INSTAR_Cloud/IFTTT/User_Guide/How_to_add_P2P_Credentials/"
        }}>{`INSTAR IFTTT User Guide`}</a>
        <ul parentName="li">
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/Motion_Detection/INSTAR_Cloud/IFTTT/User_Guide/How_to_add_P2P_Credentials/"
            }}>{`Adding your P2P ID to the INSTAR Cloud platform`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/Motion_Detection/INSTAR_Cloud/IFTTT/User_Guide/How_to_use_INSTAR_Applets/"
            }}>{`How to use the INSTAR IFTTT Applets?`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/Motion_Detection/INSTAR_Cloud/IFTTT/User_Guide/How_to_create_own_Applets/"
            }}>{`How to create own IFTTT Applets with INSTAR?`}</a></li>
        </ul>
      </li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/en/Motion_Detection/INSTAR_Cloud/IFTTT/INSTAR_IFTTT_Applets_Instruction/"
        }}>{`INSTAR IFTTT Applets Overview`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/en/Motion_Detection/INSTAR_Cloud/IFTTT/INSTAR_IFTTT_Triggers_and_Actions/"
        }}>{`INSTAR IFTTT Triggers & Actions`}</a></li>
    </ul>
    <EuiSpacer size="xl" mdxType="EuiSpacer" />
    <blockquote>
      <p parentName="blockquote">{`The IFTTT Service has to be activated either inside your INSTAR Cloud Account. Or by activating the IFTTT toggle below to give the INSTAR Cloud permission to control your camera through the P2P protocol. The necessary access information will be stored inside your INSTAR Cloud Account and not be forwarded to third-parties.`}</p>
    </blockquote>
    <EuiSpacer size="xl" mdxType="EuiSpacer" />
    <h2 {...{
      "id": "example-use-cases",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#example-use-cases",
        "aria-label": "example use cases permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Example Use Cases`}</h2>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/b3fe8b2a1b6715094036ca75d3708fb6/29007/IFTTT-EN_01.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "56.086956521739125%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAALCAYAAAB/Ca1DAAAACXBIWXMAAAsTAAALEwEAmpwYAAACDklEQVQoz23P2W7aQBTGcb9cH6ZP0qjqfa/bi95EqtRI6XIRpWobsiglLCGQAsVgG7ABr9ieGS//CrMoijrST2c0OuebGa0sSzY2K00FUspqT1mSFwUqzwk8F3Myxg9Cil3vZkVRhG3beJ6HUqo606rZXYOz8HHciBBwFXjA9djm5PQz519O+d7qMU1yDDfCDGJEAa7rI6Q6XKJlwYRKOCF1R6TLIdIdVTJPx5t1GfXrmHqbpdVl7fzBtR7wZ4+owCBZDVH+mH2OJuZ37Ml5HbXqI10T5c8QS51i0cJzOkymHcpFE+k0yZw71OwSYdWQdpOnGZp0Wmy1q4MssijSNYWMyWKPYtlhbLYZGffkThNhN0hXA/LOW1T9CLHsVaH7nEOg2NV4ckM4uCD6e0nY/0U+r/MwbmNNO2R2A4Ietz8+0fv4EtpHpItngZsbxXyrer55jRieb5lX1Yv0fpOLn98JjFtYtTg7ec/XNy/g/hWJ3dl9t1H1arFVo2LWSGY3xI1j4ssPW3fHJPNrHGtAt9cjMG5AP+f3t3c8nr2mHB2ztq5I9hlWDU0VIQf5tqZljChjcmJEFrJaLQjDgFSGlMTMHJ0kW5MhDjN7msx89lRVPaxEskgiDKOLOR1gzYbMHZ3pfIhh9UmlS5b7SOXydH5Dy4uE51wpiTNBHHusY49EBKQiJEmDisrW/G9u4x8/7DVTh8nfIgAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/b3fe8b2a1b6715094036ca75d3708fb6/e4706/IFTTT-EN_01.avif 230w", "/en/static/b3fe8b2a1b6715094036ca75d3708fb6/d1af7/IFTTT-EN_01.avif 460w", "/en/static/b3fe8b2a1b6715094036ca75d3708fb6/7f308/IFTTT-EN_01.avif 920w", "/en/static/b3fe8b2a1b6715094036ca75d3708fb6/e1c99/IFTTT-EN_01.avif 1380w", "/en/static/b3fe8b2a1b6715094036ca75d3708fb6/2babf/IFTTT-EN_01.avif 1600w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/b3fe8b2a1b6715094036ca75d3708fb6/a0b58/IFTTT-EN_01.webp 230w", "/en/static/b3fe8b2a1b6715094036ca75d3708fb6/bc10c/IFTTT-EN_01.webp 460w", "/en/static/b3fe8b2a1b6715094036ca75d3708fb6/966d8/IFTTT-EN_01.webp 920w", "/en/static/b3fe8b2a1b6715094036ca75d3708fb6/445df/IFTTT-EN_01.webp 1380w", "/en/static/b3fe8b2a1b6715094036ca75d3708fb6/fad48/IFTTT-EN_01.webp 1600w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/b3fe8b2a1b6715094036ca75d3708fb6/81c8e/IFTTT-EN_01.png 230w", "/en/static/b3fe8b2a1b6715094036ca75d3708fb6/08a84/IFTTT-EN_01.png 460w", "/en/static/b3fe8b2a1b6715094036ca75d3708fb6/c0255/IFTTT-EN_01.png 920w", "/en/static/b3fe8b2a1b6715094036ca75d3708fb6/b1001/IFTTT-EN_01.png 1380w", "/en/static/b3fe8b2a1b6715094036ca75d3708fb6/29007/IFTTT-EN_01.png 1600w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/b3fe8b2a1b6715094036ca75d3708fb6/c0255/IFTTT-EN_01.png",
              "alt": "INSTAR Cloud IFTTT Support",
              "title": "INSTAR Cloud IFTTT Support",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/a206bc8ea6e365a4ef22ff713ce8ea99/29007/IFTTT-EN_03.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "56.086956521739125%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAALCAYAAAB/Ca1DAAAACXBIWXMAAAsTAAALEwEAmpwYAAAC60lEQVQoz1WR708bBRjH7y/wjYlGEzPnkrmF64+7Xu/OAqEEGFEIGzCzFWItpaWCDuaYAhUZA9kSzTTGV8bEGWN8q69MtsSYbGMNwyJZ2MAVXHRbgrveAh2MtnfXj9nNF/oknzzvvk8+z1f4dKSRzPe7+SDVzrmJ9zjzfj8fpweZOpFksOs1Uh1h0v1xpt9t47PjT9EkexgdSJCKdPLm4YPEj3TQGg5R9cIzeF98HiHd08xk3MvQ6/WM9bSRTnQyHGniZKSB0eirDB8NMzMUZaS3nb7mp2mR93EkHORQjUKTItIk76c1JBPc/SzqS88h5K5nWV74ldziHL8vzLL02zWW5mdZzl7h2i8/MXvhB1YWM7zVE8O3dx91QQnp5T18ce4TTrzzNvv37KJOC9BYo9PW3IDAf6YCbO+U2NjaoQDcWMmRXVhk3TBJ9vUher345QCtbQfZKGxx6eocYk09h4920dXdTTTWg1AqFrGsMo5tUyg8JDM3z/LKLag42FbZPfTQNIlFo3i9XmRJoqWlhctXMly4+DPV1dVIfj+SLBPSNIRyuYTj2G6gbdvkVtf4+85d+DfsdjbLpe++IZFM4BFFdF1DVVUmTk1ybHAISZJQdR1/VRUd4VqEsmXjVHiC4zxxtyx35a5muPjl55ib94gnkoiiB/2VEKqmoakqfr8fJRhEEUUO1YaYODWKUNwusGOsUtpcdwNty8Iul3EqFdaycxjb62wUH9DXGyOs7OVArYSiKGiajq7ryIEAdZrKyNhJJqY/RNi6v4b1aJOd/G2s4jaOU3HVbct2SzIz35K/s0R6OEU00knqjXZq9QBKUEPTNAIBmfoDzYz1Rzg9MoBQLBhUKg7Wow0cq0TFVbddZdO4x/xXQyzP/sjgQILeeJxkrJuAX8Tr9eHz+fB4Hr9BJ9IoEe9sQLhvGOTzeRfjMYbhYpomd//6k5s3l/hj9RZfnz/P+Pg4U1PTzMzM/J+Pppk6PcnZs2f4B+SYUmXUiecpAAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/a206bc8ea6e365a4ef22ff713ce8ea99/e4706/IFTTT-EN_03.avif 230w", "/en/static/a206bc8ea6e365a4ef22ff713ce8ea99/d1af7/IFTTT-EN_03.avif 460w", "/en/static/a206bc8ea6e365a4ef22ff713ce8ea99/7f308/IFTTT-EN_03.avif 920w", "/en/static/a206bc8ea6e365a4ef22ff713ce8ea99/e1c99/IFTTT-EN_03.avif 1380w", "/en/static/a206bc8ea6e365a4ef22ff713ce8ea99/2babf/IFTTT-EN_03.avif 1600w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/a206bc8ea6e365a4ef22ff713ce8ea99/a0b58/IFTTT-EN_03.webp 230w", "/en/static/a206bc8ea6e365a4ef22ff713ce8ea99/bc10c/IFTTT-EN_03.webp 460w", "/en/static/a206bc8ea6e365a4ef22ff713ce8ea99/966d8/IFTTT-EN_03.webp 920w", "/en/static/a206bc8ea6e365a4ef22ff713ce8ea99/445df/IFTTT-EN_03.webp 1380w", "/en/static/a206bc8ea6e365a4ef22ff713ce8ea99/fad48/IFTTT-EN_03.webp 1600w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/a206bc8ea6e365a4ef22ff713ce8ea99/81c8e/IFTTT-EN_03.png 230w", "/en/static/a206bc8ea6e365a4ef22ff713ce8ea99/08a84/IFTTT-EN_03.png 460w", "/en/static/a206bc8ea6e365a4ef22ff713ce8ea99/c0255/IFTTT-EN_03.png 920w", "/en/static/a206bc8ea6e365a4ef22ff713ce8ea99/b1001/IFTTT-EN_03.png 1380w", "/en/static/a206bc8ea6e365a4ef22ff713ce8ea99/29007/IFTTT-EN_03.png 1600w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/a206bc8ea6e365a4ef22ff713ce8ea99/c0255/IFTTT-EN_03.png",
              "alt": "INSTAR Cloud IFTTT Support",
              "title": "INSTAR Cloud IFTTT Support",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/2f8fd9790f73786350a1a61e2706912a/29007/IFTTT-EN_04.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "56.086956521739125%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAALCAYAAAB/Ca1DAAAACXBIWXMAAAsTAAALEwEAmpwYAAADEElEQVQoz1WP20/TBwCFf//Aspcle9nLzIYEe/3RUmhLKRVq6SgF0ToU2lqKRTow2uGUEi+MBILAtmxZYpZMky3LliyhS5agziuk5RIpiNgyqEKCo6brFipLFUS/ZWQP2/dy3s53jtDfbmLsu7cJNu/lQmeAvo5Wej7y03vqAzp8dXir9Jxp83Gu7T0+Of465fky/PX7OeY5hM2owyBKMOZLMapk6GS5CKdcZZz3yGmtNXCywUqn7wAn6y0EHEZOOytoP2hi8HQz7e4qfJY3sMjfxWFQUV2sxlokYtXIMOfvYo9aSqk8B2Fxdop49C6LM+P8Gg3zIDpJdGKUe9EId64M8cvQt4Rv/MwRVwOKnbmUqJUod+7gi8F+jrU0k/PODsqKtZiLi6ipKEfgP7wCss82SD/N8hJ4/OR3Hj5aZnnlMW6PB6lcjkIpUlVdQ2Y9y+3RCDKNFvveWhwH3qfB5UbIZrNsbGywubnJ2lqG8Ngk8fg8vNzi1dbWtuhZJoPb5UQmkyEqlVRW2hgNT3Dl6nX0Wi0KhQK5UkmhWv3/hf+QXF1lPZ2Gf8uWpqa4881lvE1epBIJhRoNBQUFnD1zFr+/BYVMgkolIubl4thdgjAyMU1kLsH4TJyJ6Rh3ZxeYvhfn+YsXJMbHuXbxM/7I/IbH24REIqWwSItarUatKyVfZ0JTYkZXasFqsuA/HkAIfT/E9WAXP/0QYuz+PDPxJULDN1lcTLByf4bUepK1539ypNGNUZWDWa9EFEXqnE1U73Oz/6CXssp6nL4PuTUWRZgfDvFAl0ePowvn4Ulis2lSqRVisfj25XTkMumVOToCPpx1tficNehVIga7npzzb5LX+BatR1/j4y4DyWQKITL0JYkTHj5v+wqXM8KNW6MkVx8Sjy3w19M0kxdbiYdDtLV4afR48B4+hLgrD8+Jehqu1RL48ShXh/dx83Yny0urCBXmcuw1dmx2G/bqSqy2PZSZdjMyMkI69YS5uVkeJRb4+tIlgsEg3d3d23muM8hAzwCDfYP09g7Q3/8pF3r7+BsWFT2NoUVD/AAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/2f8fd9790f73786350a1a61e2706912a/e4706/IFTTT-EN_04.avif 230w", "/en/static/2f8fd9790f73786350a1a61e2706912a/d1af7/IFTTT-EN_04.avif 460w", "/en/static/2f8fd9790f73786350a1a61e2706912a/7f308/IFTTT-EN_04.avif 920w", "/en/static/2f8fd9790f73786350a1a61e2706912a/e1c99/IFTTT-EN_04.avif 1380w", "/en/static/2f8fd9790f73786350a1a61e2706912a/2babf/IFTTT-EN_04.avif 1600w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/2f8fd9790f73786350a1a61e2706912a/a0b58/IFTTT-EN_04.webp 230w", "/en/static/2f8fd9790f73786350a1a61e2706912a/bc10c/IFTTT-EN_04.webp 460w", "/en/static/2f8fd9790f73786350a1a61e2706912a/966d8/IFTTT-EN_04.webp 920w", "/en/static/2f8fd9790f73786350a1a61e2706912a/445df/IFTTT-EN_04.webp 1380w", "/en/static/2f8fd9790f73786350a1a61e2706912a/fad48/IFTTT-EN_04.webp 1600w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/2f8fd9790f73786350a1a61e2706912a/81c8e/IFTTT-EN_04.png 230w", "/en/static/2f8fd9790f73786350a1a61e2706912a/08a84/IFTTT-EN_04.png 460w", "/en/static/2f8fd9790f73786350a1a61e2706912a/c0255/IFTTT-EN_04.png 920w", "/en/static/2f8fd9790f73786350a1a61e2706912a/b1001/IFTTT-EN_04.png 1380w", "/en/static/2f8fd9790f73786350a1a61e2706912a/29007/IFTTT-EN_04.png 1600w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/2f8fd9790f73786350a1a61e2706912a/c0255/IFTTT-EN_04.png",
              "alt": "INSTAR Cloud IFTTT Support",
              "title": "INSTAR Cloud IFTTT Support",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/d92c3ce2addf5228c785e30f41b5369d/29007/IFTTT-EN_05.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "56.086956521739125%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAALCAYAAAB/Ca1DAAAACXBIWXMAAAsTAAALEwEAmpwYAAADBUlEQVQozzWP609bBRjGzz/hR50uZAFGa1GkayrQZVOUfhhzOudQxmkrMJQFNxMvk00qzAW8RJyddQl1MowZjGRx6wTcXGUtN8O8LbAiyMJY1AO9s56eS3+GTp/kyfs8X355XqHo4ginRp/ngaFbWIIpyoIxrME4xkCMx4MxCgMxNo9E2f9OA13HDFh3O2k47Kb53WO80voeLe7j1B58E7tjP4ann0HYOrrEvlAIWyjGsz8lsE/E2T4Wz8GeHI9TEIhRFYrxUnMTz9WUUmrfRVWNyBM1TrbVNlC2R6TiBQeVYiOmyh0IiqKR1kHXde6qOosJmeVIHCmVZiWtIckaCVnBITrZlG9kyxYzG/PyeN39PlO/z/DD2GTuXp2+wd6XGxEymQxZTUXXNDKazl/JNPJainUlZZhaTBFXVFxOBwajAavVyqb8Arp7TvO/tCzciuq4Wg4haLqOBujZLGsZjTuJu6hymoycZmTkMrejMhH5HrCwIB9z6aM8+NAGTn51hvDsDOGbs/9BszgOHEQYuhzA5xvgbL+fc9+P8uvyP8TTMotSjO8mZogqGqtpBZdDxFBcSlmFnc0mK8e9vVz9ZYHxG/PcllYIL//Ni42vIvw4PsmdTz4k9NohBv1z3Pwzw9JKlLGff0MHYrJKRlFxiCL26l3sbnGyp8lJ1c6nOHXGxzn/MMUWK+WVVdi2bUcYDlxiun4nvSUVlJjOc/gNP8nEElMTU7lXspqGqiq4RBdGcyF5nvt47K37aXtbYGj4CH19g+Rt3EDJI8VUlJcjDPT30v/ZB5z1ePB6L3L662+48O0gwWuhHFBVVRR1faGD5gMNeIa6+fLCF1zyf8T09St4Pj2J8WEjZrMZm82GsDC/wOT0da5NjDPQ30c4PMsfc/Mkk8l7C7PZHLSuro7qHdWIe0VOfHyCrq5u6uub2Fdbi8lkoqioCIvFghCJRFhLpViRJObm5lnviWSC1dVVJEnKeT37fD5aW1tpc7fR4+vB6/2co0eP4Ha76ejooL29nc7OTv4FZE6BbIDa1rkAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/d92c3ce2addf5228c785e30f41b5369d/e4706/IFTTT-EN_05.avif 230w", "/en/static/d92c3ce2addf5228c785e30f41b5369d/d1af7/IFTTT-EN_05.avif 460w", "/en/static/d92c3ce2addf5228c785e30f41b5369d/7f308/IFTTT-EN_05.avif 920w", "/en/static/d92c3ce2addf5228c785e30f41b5369d/e1c99/IFTTT-EN_05.avif 1380w", "/en/static/d92c3ce2addf5228c785e30f41b5369d/2babf/IFTTT-EN_05.avif 1600w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/d92c3ce2addf5228c785e30f41b5369d/a0b58/IFTTT-EN_05.webp 230w", "/en/static/d92c3ce2addf5228c785e30f41b5369d/bc10c/IFTTT-EN_05.webp 460w", "/en/static/d92c3ce2addf5228c785e30f41b5369d/966d8/IFTTT-EN_05.webp 920w", "/en/static/d92c3ce2addf5228c785e30f41b5369d/445df/IFTTT-EN_05.webp 1380w", "/en/static/d92c3ce2addf5228c785e30f41b5369d/fad48/IFTTT-EN_05.webp 1600w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/d92c3ce2addf5228c785e30f41b5369d/81c8e/IFTTT-EN_05.png 230w", "/en/static/d92c3ce2addf5228c785e30f41b5369d/08a84/IFTTT-EN_05.png 460w", "/en/static/d92c3ce2addf5228c785e30f41b5369d/c0255/IFTTT-EN_05.png 920w", "/en/static/d92c3ce2addf5228c785e30f41b5369d/b1001/IFTTT-EN_05.png 1380w", "/en/static/d92c3ce2addf5228c785e30f41b5369d/29007/IFTTT-EN_05.png 1600w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/d92c3ce2addf5228c785e30f41b5369d/c0255/IFTTT-EN_05.png",
              "alt": "INSTAR Cloud IFTTT Support",
              "title": "INSTAR Cloud IFTTT Support",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/e5d932aff76aa6c41688cde2c2926b8d/29007/IFTTT-EN_06.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "56.086956521739125%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAALCAYAAAB/Ca1DAAAACXBIWXMAAAsTAAALEwEAmpwYAAACQ0lEQVQoz3WSy27TQBSG/Qx9CZAQiNIFYoGEWLNsaaBCiLcpvASwYYEQFSAkLpWaXqANrXuBKgXaNGlELs3F9tixY8+M/SFPUqkSYqTj8Tn+ff7/PzOW9abHpaLLtRWPyRWPqRWPC0suN9byPODi0iFTKxtcXT7hStHnctFlctnj+qrHxIc+Ex8drHc9rIUu1kIP6/a64K7tM2cLCls+c9s+M1sBs7ZPwQ6Z3qrxYMdmdqfJ9PaQe/l3O6CwE3J/N+TRbsCdkuDmF8GtrwKL8UqAGFD8ZyUKKRya9RpHB2UGTg8dDf6BWTKDmoh4+eo1z589xf5VIQKyLEMqTa/f56R+wp9Gg9VNm+LiJ/bsTdZLJRqtNrWTOu3TDmEco/OG+eOg3uBhYYbH8/MG5GjQMqFWq9HpdEikNOybP494/3mRUmmD7f2yqSmtcV2XarVKEARYKdAKE/bsDVq13/RlRqhSyFKE758zk+FpCIEAcFNjYxSA7/tIKbHygsrAS6EVSUSiDSBNUzwhxq2gMUw5HGgOfMW+kBwGytSyMZ0QAqXU6FAcf8i3/Sb1UCPUCKLTlMFgQL1e5+i4SqDBHUoGUhOozOTd3NmPfcIwNHZHCoFS+ZQnL7aJVYoeU+YKc2Cj0aTdbo9IZEKmNXo801RJKpVjkiQx5Ebh96pgvdzh7VqFOFFmdrm6vGG32zXhOA6e5xlbed5stszMHNel3++bQ8nreWMrihXhMCGKpbkm6bhZHnEcG5VRFJn9LM7nZ+/5nv/zF3IhIc0l1w37AAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/e5d932aff76aa6c41688cde2c2926b8d/e4706/IFTTT-EN_06.avif 230w", "/en/static/e5d932aff76aa6c41688cde2c2926b8d/d1af7/IFTTT-EN_06.avif 460w", "/en/static/e5d932aff76aa6c41688cde2c2926b8d/7f308/IFTTT-EN_06.avif 920w", "/en/static/e5d932aff76aa6c41688cde2c2926b8d/e1c99/IFTTT-EN_06.avif 1380w", "/en/static/e5d932aff76aa6c41688cde2c2926b8d/2babf/IFTTT-EN_06.avif 1600w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/e5d932aff76aa6c41688cde2c2926b8d/a0b58/IFTTT-EN_06.webp 230w", "/en/static/e5d932aff76aa6c41688cde2c2926b8d/bc10c/IFTTT-EN_06.webp 460w", "/en/static/e5d932aff76aa6c41688cde2c2926b8d/966d8/IFTTT-EN_06.webp 920w", "/en/static/e5d932aff76aa6c41688cde2c2926b8d/445df/IFTTT-EN_06.webp 1380w", "/en/static/e5d932aff76aa6c41688cde2c2926b8d/fad48/IFTTT-EN_06.webp 1600w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/e5d932aff76aa6c41688cde2c2926b8d/81c8e/IFTTT-EN_06.png 230w", "/en/static/e5d932aff76aa6c41688cde2c2926b8d/08a84/IFTTT-EN_06.png 460w", "/en/static/e5d932aff76aa6c41688cde2c2926b8d/c0255/IFTTT-EN_06.png 920w", "/en/static/e5d932aff76aa6c41688cde2c2926b8d/b1001/IFTTT-EN_06.png 1380w", "/en/static/e5d932aff76aa6c41688cde2c2926b8d/29007/IFTTT-EN_06.png 1600w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/e5d932aff76aa6c41688cde2c2926b8d/c0255/IFTTT-EN_06.png",
              "alt": "INSTAR Cloud IFTTT Support",
              "title": "INSTAR Cloud IFTTT Support",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <EuiSpacer mdxType="EuiSpacer" />
    <ForumBox mdxType="ForumBox" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      